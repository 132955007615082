import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { Button } from 'primeng/button';

@Component({
  selector: 'cp-consent-management',
  standalone: true,
  imports: [CommonModule, Button],
  templateUrl: './consent-management.component.html',
  styleUrl: './consent-management.component.scss',
})
export class ConsentManagementComponent {
  $link = input.required<string>();

  // consent management texts
  consentManagementText = $localize`Manage Tracking Consents`;
  consentManagementInfoText = $localize`Click the button below to manage the consents for tracking document downloads.`;
  documentDownloadTrackingConsentLinkText = $localize`Manage Consents`;

  onClickManageConsents(): void {
    window.open(this.$link(), '_blank');
  }
}
