<div>
  <h4 class="mb-0">{{ consentManagementText }} </h4>
  <small class="block">{{ consentManagementInfoText }}</small>

  <p-button
    [label]="documentDownloadTrackingConsentLinkText"
    (onClick)="onClickManageConsents()"
    styleClass="mt-4 ev-secondary-button"
  ></p-button>
</div>
